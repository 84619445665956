<template>
  <div class="flex-col section_6 space-y-59">
    <div class="flex-col items-center space-y-19">
      <span class="font_3">崤云价值观</span>
      <span class="font_5">知之真切笃实处即是行</span>
    </div>
    <div class="flex-row">
      <div class="flex-col items-center group_10 space-y-11">
        <img
          class="image_11"
          src="../../assets/a69b083e6dd954771e2dbda74672f400.png"
        />
        <span class="font_6 text_4">奋斗精神</span>
        <span class="font_7 text_4">正青春 一起拼</span>
      </div>
      <div class="flex-col items-center group_11 view space-y-11">
        <img
          class="image_11"
          src="../../assets/da1bc5f4f31d107506aa3cd1f57246fa.png"
        />
        <span class="font_6 text_4">使命</span>
        <span class="font_7 text_4">让您的数字政府建设放心、满意</span>
      </div>
      <div class="flex-row group_12 space-x-33">
        <div class="flex-col items-center group_10 space-y-11">
          <img
            class="image_11"
            src="../../assets/f9a493b73efe12a59c94c540c7cad346.png"
          />
          <span class="font_6 text_4">愿景</span>
          <span class="font_7 text_4"
            >成为国内懂政府、值得信赖的国有科技创新企业</span
          >
        </div>
        <div class="flex-col items-center group_11 space-y-11">
          <img
            class="image_11"
            src="../../assets/d52000feff98f0495564de2106d985de.png"
          />
          <span class="font_6 text_4">价值观</span>
          <span class="font_7 text_4"
            >学习成长、勇于担当、追求极致、团结协作</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.section_6 {
  padding: 3.125vw 22.0833vw 3.3333vw 22.3958vw;
  background-color: #f9faff;
  .space-y-19 {
    & > *:not(:first-child) {
      margin-top: 0.9896vw;
    }
    .font_3 {
      font-size: 1.4583vw;
      font-family: "Source Han Sans CN";
      line-height: 1.4583vw;
      font-weight: 500;
      color: #041332;
    }
    .font_5 {
      font-size: 0.8333vw;
      font-family: "Source Han Sans CN";
      line-height: 0.8333vw;
      color: #8a9199;
    }
  }
  .group_10 {
    width: 9.8958vw;
    height: 5.9375vw;
  }
  .space-y-11 {
    & > *:not(:first-child) {
      margin-top: 0.5729vw;
    }
  }
  .group_11 {
    height: 5.9375vw;
  }
  .view {
    margin-left: 3.6458vw;
  }
  .image_11 {
    width: 3.125vw;
    height: 3.125vw;
  }
  .font_6 {
    font-size: 0.8333vw;
    font-family: "Source Han Sans CN";
    line-height: 0.8333vw;
    font-weight: 700;
    color: #041332;
  }
  .text_4 {
    text-transform: uppercase;
    text-align: center;
  }
  .font_7 {
    font-size: 0.8333vw;
    font-family: "Source Han Sans CN";
    line-height: 1.0417vw;
    color: #7a8a99;
  }
  .group_12 {
    margin-left: 2.8646vw;
    width: 27.4479vw;
  }
  .space-x-33 {
    & > *:not(:first-child) {
      margin-left: 1.7188vw;
    }
  }
}
.space-y-59 {
  & > *:not(:first-child) {
    margin-top: 3.0729vw;
  }
}
</style>
