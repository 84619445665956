<template>
  <div class="flex-col section_21">
    <div class="flex-row justify-between group_25">
      <div class="flex-col group_26 self-start">
        <span class="font_16">快捷导航</span>
        <span class="font_17 text_24" @click="router.push('/solution')"
          >解决方案</span
        >
        <span class="font_17 text_26" @click="router.push('/product')"
          >产品服务</span
        >
        <span class="font_17 text_28" @click="router.push('/news')"
          >新闻动态</span
        >
        <span
          class="font_17 text_30"
          @click="openNewTab('https://www.wjx.cn/vm/tLDaerf.aspx#')"
          >与我们合作</span
        >
      </div>
      <div class="flex-col items-start self-start group_27">
        <span class="font_16">联系我们</span>
        <span class="font_17 text_25">地址：河南省三门峡市传媒大厦11楼</span>
        <span class="font_17 text_29">邮箱：Marketing@smxxy.cn</span>
      </div>
      <!-- <div class="flex-col items-start self-start group_27">
        <span class="font_16">友情链接</span>
        <span class="font_17 text_25">三门峡崤云视联科技有限公司</span>
        <span class="font_17 text_27">三门峡崤云视联科技有限公司</span>
        <span class="font_17 text_29">三门峡崤云安全服务有限公司</span>
      </div> -->
      <div class="flex-col self-center group_28 space-y-39">
        <span class="self-start font_16">关注我们</span>
        <div class="flex-col space-y-14">
          <span class="self-center font_17">崤云信息公众号</span>
          <div class="flex-col justify-start items-center image-wrapper">
            <img
              class="image_25"
              src="../../assets/59b75631268a905b9b560dc51af06386.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section_22"></div>
    <span
      class="self-start font_17 text_31"
      @click="openNewTab('https://beian.miit.gov.cn/')"
    >
      豫ICP备17039311号 三门峡崤云信息服务股份有限公司 版权所有
    </span>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const openNewTab = (url) => {
  open(url);
};
</script>

<style scoped lang="scss">
.section_21 {
  padding: 0 18.75vw 4.6875vw;
  background-color: #2c2d32;
  .group_25 {
    padding: 3.4375vw 0 2.8125vw;
    .group_26 {
      height: 10.1563vw;
      span {
        cursor: pointer;
      }
      .text_24 {
        margin-top: 2.0313vw;
      }
      .text_26 {
        margin-top: 1.4583vw;
      }
      .text_28 {
        margin-top: 1.4583vw;
      }
      .text_30 {
        margin-top: 1.4583vw;
      }
    }
    .group_27 {
      height: 7.9687vw;
      .text_25 {
        margin-top: 2.0313vw;
      }
      .text_27 {
        margin-top: 1.4583vw;
      }
      .text_29 {
        margin-top: 1.4583vw;
      }
    }
    .font_16 {
      font-size: 0.8333vw;
      font-family: "Source Han Sans CN";
      line-height: 0.8333vw;
      font-weight: 700;
      color: #ffffff;
    }
    .group_28 {
      margin-right: 3.75vw;
      width: 5.7292vw;
      height: 10.0521vw;
      .space-y-14 {
        & > *:not(:first-child) {
          margin-top: 0.7292vw;
        }
        .image-wrapper {
          padding: 0.4167vw 0;
          border-radius: 0.1563vw;
          background-image: url("../../assets/106fea043fbf04ae0dbad2d3667d66b3.png");
          background-position: 0% 0%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .image_25 {
            width: 5vw;
            height: 5vw;
          }
        }
      }
    }
    .space-y-39 {
      & > *:not(:first-child) {
        margin-top: 2.0313vw;
      }
    }
  }
  .section_22 {
    background-color: #e6e6e633;
    height: 1px;
  }
  .font_17 {
    font-size: 0.7292vw;
    font-family: "Source Han Sans CN";
    line-height: 0.7292vw;
    font-weight: 200;
    color: #ffffff99;
    white-space: nowrap;
  }
  .text_31 {
    margin-top: 4.7917vw;
    font-weight: unset;
    cursor: pointer;
  }
}
</style>
