<template>
  <div class="flex-col space-y-56 self-center">
    <div class="flex-col items-center self-center space-y-20">
      <span class="font_3">核心竞争力</span>
      <span class="font_5">行之明觉精察处即是知</span>
    </div>
    <div class="flex-row space-x-12">
      <div class="flex-col justify-start relative group_14">
        <div class="flex-col section_7">
          <div class="flex-col items-start group_15">
            <span class="font_8 text_4">government</span>
            <span class="font_9 text_10">懂政府</span>
          </div>
          <div class="self-start section_10"></div>
        </div>
        <div class="flex-col justify-start items-center text-wrapper pos_7">
          <span class="font_10 text_11">
            崤云公司常年经营政府行业应用，了解政府业务部门需求导向，针对政策进行精准解读，解决各局委信息化方面的难题。崤云公司根据用户所需，建立一套完善的体制机制，即“产品-运维-运营”全流程把控，帮助政府提升办公效率，达到“业务流程精准化、业务办理便捷化”的建设目标。
          </span>
        </div>
      </div>
      <div class="flex-col justify-start relative group_16">
        <div class="flex-col justify-start section_8">
          <div class="flex-col justify-start items-center relative group_17">
            <img
              class="shrink-0 image_12"
              src="../../assets/9abb012323afd75a8b24043c302b4319.png"
            />
            <div class="shrink-0 section_11 pos_5"></div>
            <span class="font_8 text_4 pos">operate</span>
            <span class="font_9 pos_3">懂运营</span>
          </div>
        </div>
        <div class="flex-col section_13 space-y-18 pos_8">
          <span class="font_10 text_12">
            崤云公司把脉运营三大要素，在资源整合的基础上，以满足民众需求为出发点，提供政务服务解决方案。
          </span>
          <div class="flex-row group_18 space-x-8">
            <img
              class="shrink-0 self-start image_13 image_14"
              src="../../assets/a1db0b74d60d5d3b2fa2ed7d98f9f274.png"
            />
            <span class="flex-auto font_11 text_14"
              >产品精准服务分析政府的业务需求，分析产品应用场景，明确业务导向</span
            >
          </div>
          <div class="flex-row items-center space-x-9">
            <img
              class="shrink-0 image_13"
              src="../../assets/815e605898b0de62bd26cddc79d9ad41.png"
            />
            <span class="font_11 text_15"
              >构建完整的用户画像，增益定制化业务</span
            >
          </div>
          <div class="flex-row group_20 space-x-8">
            <img
              class="shrink-0 self-start image_13 image_15"
              src="../../assets/ed464c6d0cfd4a21d9d85b22e531f520.png"
            />
            <span class="flex-auto font_11 text_16"
              >大数据分析平台助力智慧城市建设，为多元化业务开展打好坚实基础</span
            >
          </div>
        </div>
      </div>
      <div class="flex-col justify-start relative group_16">
        <div class="flex-col justify-start section_9">
          <div class="flex-col justify-start items-center relative group_17">
            <img
              class="shrink-0 image_12"
              src="../../assets/9abb012323afd75a8b24043c302b4319.png"
            />
            <div class="shrink-0 section_12 pos_6"></div>
            <span class="font_8 text_4 pos_2">technology</span>
            <span class="font_9 pos_4">技术自主可控</span>
          </div>
        </div>
        <div class="flex-col justify-start items-center text-wrapper_2 pos_9">
          <span class="font_10 text_13">
            崤云公司拥有一支高素质、高效率的技术团队，技术人员占公司总人数70%，团队核心成员均来自世界500强企业。遵循“自主可控，安全可靠可持续”的原则，我们梳理了一整套独有的技术体系和规范，并通过学习和实践不断完善，具备独立自主研发、独立交付、独立运维及运营能力。
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.space-y-56 {
  & > *:not(:first-child) {
    margin-top: 2.9167vw;
  }
  .space-y-20 {
    & > *:not(:first-child) {
      margin-top: 1.0417vw;
    }
    .font_3 {
      font-size: 1.4583vw;
      font-family: "Source Han Sans CN";
      line-height: 1.4583vw;
      font-weight: 500;
      color: #041332;
    }
    .font_5 {
      font-size: 0.8333vw;
      font-family: "Source Han Sans CN";
      line-height: 0.8333vw;
      color: #8a9199;
    }
  }
  .space-x-12 {
    & > *:not(:first-child) {
      margin-left: 0.625vw;
    }
    .group_14 {
      padding-bottom: 13.6458vw;
      flex: 1 1 20.3646vw;
      width: 20.3646vw;
      .section_7 {
        padding: 0 1.5104vw 4.6875vw;
        border-radius: 0.4167vw;
        background-image: url("../../assets/f7bd416a8695d37b9a08fc558194c777.png");
        background-position: 0% 0%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 20.3646vw;
        .group_15 {
          padding: 1.875vw 0 0.8333vw;
          height: 5.5208vw;
          .text_10 {
            margin-top: -0.5208vw;
          }
        }
        .section_10 {
          background-color: #46c3d7;
          width: 3.125vw;
          height: 0.2083vw;
        }
      }
      .text-wrapper {
        padding: 0.9375vw 0 4.6354vw;
        background-image: linear-gradient(180deg, #f9fbff 0%, #ffffff 100%);
        box-shadow: 0px 0px 1.5625vw 0px #14409e1a;
        border-radius: 0.4167vw;
        width: 18.8542vw;
        .text_11 {
          text-align: justify;
          width: 14.1667vw;
        }
      }
      .pos_7 {
        position: absolute;
        left: 0.7292vw;
        right: 0.7813vw;
        top: 6.7188vw;
      }
    }
    .group_16 {
      flex: 1 1 20.3646vw;
      padding-bottom: 13.6458vw;
      width: 20.4688vw;
      .section_8 {
        border-radius: 0.4167vw;
        background-image: url("../../assets/2c55f62bdd31fb4886e23ff309c5b9d1.png");
        background-position: 0% 0%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 20.4688vw;
      }
      .section_13 {
        padding: 0.9896vw 1.9271vw 2.7083vw;
        background-image: linear-gradient(180deg, #f9fbff 0%, #ffffff 100%);
        box-shadow: 0px 0px 1.5625vw 0px #14409e1a;
        border-radius: 0.4167vw;
        width: 18.8542vw;
        .text_12 {
          margin-left: 0.2083vw;
          margin-right: 0.625vw;
          text-align: justify;
        }
        .group_18 {
          margin-right: 0.4167vw;
          .image_14 {
            margin-top: 0.2083vw;
          }
          .text_14 {
            text-align: justify;
          }
        }
        .space-x-8 {
          & > *:not(:first-child) {
            margin-left: 0.4167vw;
          }
        }
        .space-x-9 {
          & > *:not(:first-child) {
            margin-left: 0.4688vw;
          }
          .text_15 {
            line-height: 0.7292vw;
          }
        }
        .image_13 {
          border-radius: 0.1042vw;
          width: 1.0417vw;
          height: 1.0417vw;
        }
        .font_11 {
          font-size: 0.7292vw;
          font-family: "Source Han Sans CN";
          line-height: 1.4583vw;
          color: #7a8a99;
        }
        .group_20 {
          margin-right: 0.4167vw;
          .image_15 {
            margin-top: 0.1563vw;
          }
          .text_16 {
            text-align: justify;
          }
        }
      }
      .space-y-18 {
        & > *:not(:first-child) {
          margin-top: 0.9375vw;
        }
      }
      .pos_8 {
        position: absolute;
        left: 0.8333vw;
        right: 0.7813vw;
        top: 6.7708vw;
      }
      .section_9 {
        border-radius: 0.4167vw;
        background-image: url("../../assets/88f105eda8bd5c049cede0f8812a5810.png");
        background-position: 0% 0%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 20.4688vw;
      }
      .group_17 {
        overflow: hidden;
        border-radius: 0.2083vw;
        width: 20.3646vw;
        .image_12 {
          border-radius: 0.2083vw;
          overflow: hidden;
          width: 20.3646vw;
          height: 10.4167vw;
        }
        .section_11 {
          background-color: #ea5830;
          width: 3.125vw;
          height: 0.2083vw;
        }
        .pos_5 {
          position: absolute;
          left: 1.5625vw;
          top: 5.5208vw;
        }
        .pos {
          position: absolute;
          left: 1.5104vw;
          top: 1.875vw;
        }
        .pos_3 {
          position: absolute;
          left: 1.5104vw;
          top: 3.125vw;
        }
        .section_12 {
          background-color: #3798ff;
          width: 3.125vw;
          height: 0.2083vw;
        }
        .pos_6 {
          position: absolute;
          left: 1.6146vw;
          top: 5.5208vw;
        }
        .pos_2 {
          position: absolute;
          left: 1.5104vw;
          top: 1.875vw;
        }
        .pos_4 {
          position: absolute;
          left: 1.5104vw;
          top: 3.125vw;
        }
      }
      .text-wrapper_2 {
        padding: 0.9896vw 0 4.5833vw;
        background-image: linear-gradient(180deg, #f9fbff 0%, #ffffff 100%);
        box-shadow: 0px 0px 1.5625vw 0px #14409e1a;
        border-radius: 0.4167vw;
        width: 18.8542vw;
        .text_13 {
          text-align: justify;
          width: 14.2188vw;
        }
      }
      .pos_9 {
        position: absolute;
        left: 0.8333vw;
        right: 0.7813vw;
        top: 6.7708vw;
      }
    }
    .font_8 {
      font-size: 1.7708vw;
      font-family: "Source Han Sans CN";
      line-height: 1.7708vw;
      font-weight: 200;
      color: #ffffff33;
    }
    .text_4 {
      text-transform: uppercase;
    }
    .font_9 {
      font-size: 1.5625vw;
      font-family: "Source Han Sans CN";
      line-height: 1.5625vw;
      font-weight: 900;
      color: #ffffff;
    }
    .font_10 {
      font-size: 0.8333vw;
      font-family: "Source Han Sans CN";
      line-height: 1.5625vw;
      color: #525c66;
    }
  }
}

@media (max-width: 1500px) {
  .space-y-56 .space-x-12 .group_16 .section_13 {
    padding-left: 1.3909vw;
    padding-right: 1.3909vw;
  }

  .space-y-56 .space-x-12 .group_14 .text-wrapper .text_11 {
    width: 15.24vw;
  }

  .space-y-56 .space-x-12 .group_16 .text-wrapper_2 .text_13 {
    width: 15.24vw;
  }
}
</style>
