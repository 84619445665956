<template>
  <div class="flex-col page">
    <video
      src="../assets/video/home-banner.mp4"
      loop
      muted
      autoplay
      style="width: 100%"
    ></video>
    <div
      class="flex-col justify-start section"
      @click="scrollNext"
      ref="videoMask"
    >
      <div class="flex-col justify-start section_2">
        <div class="flex-col section_3 space-y-86">
          <div class="flex-col items-center self-center space-y-15">
            <span class="text text_2">科技赋能 让城市生活更美好</span>
            <span class="text text_3"
              >智慧城市解决方案提供商，让城市管理更高效、更智慧</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="flex-row self-end group_4 space-x-78">
      <div class="flex-col shrink-0 group_5">
        <span class="self-start font_2 text_4 text_5">About Xiaoyun</span>
        <span class="self-start font_3 text_6"
          >三门峡崤云信息服务股份有限公司</span
        >
        <div class="flex-col group_6 space-y-38">
          <span class="font_4 text_7">
            三门峡崤云信息服务股份有限公司是三门峡市属国有控股企业，成立于2017年6月，注册资本1.07亿元。公司定位为新型智慧城市建设综合服务商和大数据开发商，主要开展新型智慧城市建设、运营、运维服务和大数据采集、存储、分析、挖掘以及创新应用等业务。
          </span>
          <span class="font_4 text_8">
            短短几年，创立了西安智慧政务研发中心和三门峡市研发中心，先后成立三门峡崤云安全服务有限公司、三门峡崤云视联科技有限公司、河南甘棠软件科技有限公司，员工平均年龄29岁，本科以上学历占86%，专业技术人员占78%，获得自主知识产权193项，荣获国家及行业级荣誉资质50项，省级荣誉资质52项，于2020年10月正式挂牌新三板（股票代码873496）。
          </span>
        </div>
      </div>
      <div class="flex-col flex-auto self-start group_7 space-y-60">
        <img
          class="image_5"
          src="../assets/a5b45aca3fb27537fe7d0586cbb08df0.png"
        />
        <div class="flex-row">
          <div class="flex-col items-start space-y-20">
            <img
              class="image_6"
              src="../assets/1d2c02cf875790ebc2c966ef9c3cc5fb.png"
            />
            <img
              class="image_8"
              src="../assets/96b87e95128605d768aab787474b658f.png"
            />
          </div>
          <div class="flex-col items-start group_8 space-y-20">
            <img
              class="image_7"
              src="../assets/4710ed0d457b3dad44a9e3472c62e2e5.png"
            />
            <img
              class="image_9"
              src="../assets/d4179d51de89e1fcec2ffcb1e9e084b3.png"
            />
          </div>
          <div class="flex-col items-start group_9 space-y-20">
            <img
              class="image_7"
              src="../assets/72a138b8239c33397aa5806b2dbebfef.png"
            />
            <img
              class="image_10"
              src="../assets/ebd2008385b5267f793ac877156d56cd.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col group_1">
      <XYValues />
      <div class="flex-col self-center group_13">
        <CorePower />
        <div class="flex-col items-center self-center group_21 space-y-19">
          <span class="font_3">为什么选择我们？</span>
          <span class="font_5">自主可控、安全可信，获众多合规认证</span>
        </div>
        <div class="flex-row group_22 space-x-36">
          <div class="flex-col items-center section_14 space-y-6">
            <img
              class="image_17"
              src="../assets/ba57fa4efdffaf327e1e6c72c083ac59.png"
            />
            <span class="font_12 text_17">
              国家高新技术
              <br />
              企业证书
            </span>
          </div>
          <div class="flex-col items-center section_15 space-y-9">
            <img
              class="image_19"
              src="../assets/26beeed39b6d9cff594f4d8bbb419abf.png"
            />
            <span class="font_12 text_18">
              ISO9001质量
              <br />
              管理体系认证
            </span>
          </div>
          <div class="flex-col section_16 space-y-9">
            <img
              class="self-center image_19"
              src="../assets/26beeed39b6d9cff594f4d8bbb419abf.png"
            />
            <span class="font_12 text_19">
              ISO27001信息
              <br />
              安全管理体系认证
            </span>
          </div>
          <div class="flex-col section_17 space-y-6">
            <img
              class="self-center image_18"
              src="../assets/233f2452d4f79e28eb69e9a2b327b847.png"
            />
            <span class="font_12 text_20">
              企业信用评价
              <br />
              AAA级信用企业
            </span>
          </div>
          <div class="flex-col items-center section_18 space-y-11">
            <img
              class="image_16"
              src="../assets/cfad6a2855d9e94dd54d10a80986470d.png"
            />
            <span class="font_1 text_21">CMMI 3证书</span>
          </div>
        </div>
      </div>
      <div class="flex-col group_29">
        <div class="flex-col section_19 space-y-10">
          <div class="flex-row justify-between">
            <div class="flex-row self-start group_23 space-x-6">
              <span class="font_3">科技赋能</span>
              <span class="font_3">让城市生活更美好</span>
            </div>
            <div
              class="flex-row items-center section_20 space-x-222"
              @click="router.push('/solution')"
            >
              <div class="flex-row items-center space-x-40">
                <img
                  class="image_20"
                  src="../assets/4c46c16dcb94b753058a3f25a6ccfd7f.png"
                />
                <div class="flex-col items-start space-y-20">
                  <span class="font_2">解决方案</span>
                  <span class="font_13">查看全部解决方案</span>
                </div>
              </div>
              <img
                class="image_21 image_22"
                src="../assets/f46ae006132f0bc4606b7fd26d38eefd.png"
              />
            </div>
          </div>
          <div class="flex-row justify-between">
            <div class="self-start group_24">
              <span class="font_14">
                我们深耕数字政府、数字经济、数字社会领域
                <br />
              </span>
              <span class="font_14">目前已为</span>
              <span class="font_15">2390</span>
              <span class="font_14">家政府单位</span>
              <span class="font_15">168万+</span>
              <span class="font_14">用户提供优质服务</span>
            </div>
            <div
              class="flex-row section_20 view_2"
              @click="router.push('/product')"
            >
              <div class="flex-row items-center self-center space-x-40">
                <img
                  class="image_20"
                  src="../assets/6e634b03f69fb3ca88b63b2f37fb58af.png"
                />
                <div class="flex-col items-start space-y-20">
                  <span class="font_2">产品和服务</span>
                  <span class="font_13">查看全部产品和服务</span>
                </div>
              </div>
              <img
                class="self-start image_21 image_23"
                src="../assets/f46ae006132f0bc4606b7fd26d38eefd.png"
              />
            </div>
          </div>
        </div>
        <div class="flex-col justify-start items-center relative">
          <img
            class="image_24"
            src="../assets/00e4d3f96d822fb64e1df0ab7070bed3.png"
          />
          <div class="flex-col items-center space-y-23 pos_10">
            <span class="text_22">为您提供全套智慧城市解决方案</span>
            <div class="flex-col justify-start items-center text-wrapper_3">
              <span class="text_23" @click="openNewTab">联系我们</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CorePower from "../components/CorePower/CorePower.vue";
import XYValues from "../components/XYValues/XYValues.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";

const router = useRouter();

const openNewTab = () => {
  open("https://www.wjx.cn/vm/tLDaerf.aspx#");
};

const videoMask = ref(null);

const scrollNext = () => {
  // 平滑滚动
  parent.scrollTo({
    top: videoMask.value.clientHeight,
    left: 0,
    behavior: "smooth",
  });
};
</script>

<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;
  .section {
    position: absolute;
    width: 100%;
    height: 56.25vw;
    .section_2 {
      width: 100%;
      height: 100%;
      background-color: #04133233;
      .section_3 {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background-image: linear-gradient(
          rgba(0, 92, 230, 0.1215686275) 0.24%,
          #02123091 100.27%
        );
        .space-y-15 {
          & > *:not(:first-child) {
            margin-top: 0.7813vw;
          }
          .text {
            text-shadow: 0px 0px 0.2083vw #00000080;
          }
          .text_2 {
            color: #ffffff;
            font-size: 1.9792vw;
            font-family: "Source Han Sans CN";
            font-weight: 700;
            line-height: 1.9792vw;
          }
          .text_3 {
            color: #ffffff;
            font-size: 0.9375vw;
            font-family: "Source Han Sans CN";
            line-height: 0.9375vw;
          }
        }
        .group_3 {
          width: 7.8125vw;
          .section_4 {
            background-color: #3272f1;
            width: 2.0833vw;
            height: 0.2083vw;
          }
          .section_5 {
            background-color: #ffffff4d;
            width: 2.0833vw;
            height: 0.2083vw;
          }
        }
      }
      .space-y-86 {
        & > *:not(:first-child) {
          margin-top: 4.4792vw;
        }
      }
    }
  }
  .group_4 {
    margin-top: 5.2083vw;
    width: 81.4063vw;
    .group_5 {
      padding-bottom: 0.9375vw;
      width: 30.4167vw;
      height: 28.0729vw;
      .text_4 {
        text-transform: uppercase;
      }
      .text_5 {
        color: #3272f1;
      }
      .text_6 {
        margin-top: 0.4167vw;
      }
      .group_6 {
        margin: 1.8229vw 0.1563vw 0;
        .font_4 {
          font-size: 0.8333vw;
          font-family: "Source Han Sans CN";
          line-height: 1.7708vw;
          color: #666666;
        }
        .text_7 {
          text-align: justify;
        }
        .text_8 {
          text-align: justify;
        }
        .text_9 {
          text-align: justify;
        }
      }
      .space-y-38 {
        & > *:not(:first-child) {
          margin-top: 1.9792vw;
        }
      }
    }
    .group_7 {
      margin-top: 4.6875vw;
      .image_5 {
        width: 46.9271vw;
        height: 15.9375vw;
      }
      .group_8 {
        margin-left: 4.7917vw;
        .image_9 {
          width: 8.2292vw;
          height: 0.7813vw;
        }
      }
      .group_9 {
        margin-left: 3.6458vw;
        .image_10 {
          width: 6.6146vw;
          height: 0.7813vw;
        }
      }
      .image_7 {
        width: 2.8125vw;
        height: 1.3542vw;
      }
    }
    .space-y-60 {
      & > *:not(:first-child) {
        margin-top: 3.125vw;
      }
    }
  }
  .space-x-78 {
    & > *:not(:first-child) {
      margin-left: 4.0625vw;
    }
  }
  .group_1 {
    margin-top: 5.2083vw;
    .group_13 {
      margin-top: 5.2083vw;
      .group_21 {
        margin-top: 5.1563vw;
        .font_5 {
          font-size: 0.8333vw;
          font-family: "Source Han Sans CN";
          line-height: 0.8333vw;
          color: #8a9199;
        }
      }
      .space-y-19 {
        & > *:not(:first-child) {
          margin-top: 0.9896vw;
        }
      }
      .group_22 {
        margin-top: 3.125vw;
        .section_14 {
          padding: 0.8854vw 0 1.0938vw;
          flex: 1 1 10.9896vw;
          background-color: #ffffff;
          border-radius: 0.3125vw;
          width: 10.9896vw;
          height: 7.0833vw;
          border: solid 0.05208vw #edf4fa;
          .image_17 {
            width: 2.2396vw;
            height: 2.8125vw;
          }
          .text_17 {
            text-align: center;
          }
        }
        .space-y-6 {
          & > *:not(:first-child) {
            margin-top: 0.3125vw;
          }
        }
        .section_15 {
          padding: 1.0417vw 0;
          flex: 1 1 10.9896vw;
          background-color: #ffffff;
          border-radius: 0.3125vw;
          width: 10.9896vw;
          height: 7.0833vw;
          border: solid 0.05208vw #edf4fa;
          .text_18 {
            text-align: center;
          }
        }
        .space-y-9 {
          & > *:not(:first-child) {
            margin-top: 0.4688vw;
          }
        }
        .font_12 {
          font-size: 0.8333vw;
          font-family: "Source Han Sans CN";
          line-height: 1.0417vw;
          color: #7a8a99;
        }
        .section_16 {
          padding: 1.0417vw 0;
          flex: 1 1 10.9896vw;
          background-color: #ffffff;
          border-radius: 0.3125vw;
          width: 10.9896vw;
          height: 7.0833vw;
          border: solid 0.05208vw #edf4fa;
          .text_19 {
            text-align: center;
          }
        }
        .image_19 {
          width: 3.0208vw;
          height: 2.5vw;
        }
        .section_17 {
          padding: 0.8854vw 0 1.0938vw 0;
          flex: 1 1 10.9896vw;
          background-color: #ffffff;
          border-radius: 0.3125vw;
          width: 10.9896vw;
          height: 7.0833vw;
          border: solid 0.05208vw #edf4fa;
          .image_18 {
            width: 2.8125vw;
            height: 2.8125vw;
          }
          .text_20 {
            text-align: center;
          }
        }
        .section_18 {
          padding: 0.625vw 0 1.5104vw;
          flex: 1 1 10.9896vw;
          background-color: #ffffff;
          border-radius: 0.3125vw;
          width: 10.9896vw;
          height: 7.0833vw;
          border: solid 0.05208vw #edf4fa;
          .image_16 {
            width: 6.7188vw;
            height: 3.4375vw;
          }
          .font_1 {
            font-size: 0.8333vw;
            font-family: "Source Han Sans CN";
            line-height: 0.8333vw;
            color: #ffffff;
          }
          .text_21 {
            color: #7a8a99;
          }
        }
        .space-y-11 {
          & > *:not(:first-child) {
            margin-top: 0.5729vw;
          }
        }
      }
      .space-x-36 {
        & > *:not(:first-child) {
          margin-left: 1.875vw;
        }
      }
    }
    .group_29 {
      margin-top: 5.2083vw;
      .section_19 {
        padding: 3.125vw 18.8542vw 3.125vw 21.0938vw;
        background-image: url("../assets/0e5dbef71312844512e6be1bee4cb24a.png");
        background-position: 0% 0%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .group_23 {
          margin-top: 5.2083vw;
        }
        .space-x-6 {
          & > *:not(:first-child) {
            margin-left: 0.3125vw;
          }
        }
        .section_20 {
          padding: 1.5625vw 2.0833vw;
          background-image: linear-gradient(270deg, #ffffffcc 0%, #ffffff 100%);
          border-radius: 0.2083vw;
          height: 7.2917vw;
          .space-x-40 {
            & > *:not(:first-child) {
              margin-left: 2.0833vw;
            }
            .image_20 {
              width: 4.1667vw;
              height: 4.1667vw;
            }
          }
          .image_21 {
            width: 1.5625vw;
            height: 1.1979vw;
          }
          .image_22 {
            margin-right: 0.5208vw;
          }
          .image_23 {
            margin: 1.7708vw 0.625vw 0 10.625vw;
          }
        }
        .space-x-222 {
          cursor: pointer;
          & > *:not(:first-child) {
            margin-left: 11.5625vw;
          }
        }
        .group_24 {
          line-height: 1.4583vw;
          text-align: left;
          text-transform: uppercase;
          width: 19.6354vw;
          height: 2.9167vw;
          .font_14 {
            font-size: 0.7292vw;
            font-family: "Source Han Sans CN";
            line-height: 1.4583vw;
            color: #8a9199;
          }
          .font_15 {
            font-size: 1.0417vw;
            font-family: "Source Han Sans CN";
            line-height: 1.4583vw;
            font-weight: 700;
            color: #3272f1;
          }
        }
        .view_2 {
          margin-top: 0.5208vw;
          cursor: pointer;
        }
      }
      .space-y-10 {
        & > *:not(:first-child) {
          margin-top: 0.5208vw;
        }
      }
      .image_24 {
        width: 100vw;
        height: 8.125vw;
      }
      .space-y-23 {
        & > *:not(:first-child) {
          margin-top: 1.1979vw;
        }
        .text_22 {
          color: #ffffff;
          font-size: 1.25vw;
          font-family: "Source Han Sans CN";
          font-weight: 500;
          line-height: 1.25vw;
        }
        .text-wrapper_3 {
          padding: 0.5208vw 0;
          border-radius: 0.2083vw;
          width: 8.4375vw;
          border: solid 0.05208vw #ffffff;
          .text_23 {
            color: #ffffff;
            font-size: 0.8333vw;
            font-family: "OPPOSans";
            font-weight: 500;
            line-height: 1.0938vw;
            cursor: pointer;
          }
        }
      }
      .pos_10 {
        position: absolute;
        left: 50%;
        top: 2.0313vw;
        transform: translateX(-50%);
      }
    }
  }
  .font_3 {
    font-size: 1.4583vw;
    font-family: "Source Han Sans CN";
    line-height: 1.4583vw;
    font-weight: 500;
    color: #041332;
  }
  .space-y-20 {
    & > *:not(:first-child) {
      margin-top: 1.0417vw;
    }
    .image_6 {
      width: 3.6458vw;
      height: 1.3542vw;
    }
    .image_8 {
      width: 4.8438vw;
      height: 0.7813vw;
    }
    .font_13 {
      font-size: 0.8333vw;
      font-family: "Source Han Sans CN";
      line-height: 0.8333vw;
      font-weight: 500;
      color: #8a9199;
    }
  }
  .font_2 {
    font-size: 1.0417vw;
    font-family: "Source Han Sans CN";
    line-height: 1.0417vw;
    font-weight: 500;
    color: #041332;
  }
  .fixed {
    position: fixed;
    left: 18.75vw;
    top: 1.1979vw;
  }
}

@media (min-width: 1736px) {
  .page .section .space-y-15 {
    margin-bottom: 5.4167vw;
  }
}
</style>
