import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutPage.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/News/NewsPage.vue"),
  },
  {
    path: "/news/detail/:id",
    name: "newsDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/News/NewsDetail.vue"),
  },
  {
    path: "/investor",
    name: "investor",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InvestorPage.vue"),
  },
  {
    path: "/propaganda",
    name: "propaganda",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Propaganda/PropagandaPage.vue"
      ),
  },
  {
    path: "/propaganda/detail/:id",
    name: "propagandaDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Propaganda/PropagandaDetail.vue"
      ),
  },
  {
    path: "/solution",
    name: "solution",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Solution/IndexPage.vue"),
  },
  {
    path: "/solution/1",
    name: "solution1",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail1Page.vue"
      ),
  },
  {
    path: "/solution/2",
    name: "solution2",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail2Page.vue"
      ),
  },
  {
    path: "/solution/3",
    name: "solution3",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail3Page.vue"
      ),
  },
  {
    path: "/solution/4",
    name: "solution4",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail4Page.vue"
      ),
  },
  {
    path: "/solution/5",
    name: "solution5",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail5Page.vue"
      ),
  },
  {
    path: "/solution/6",
    name: "solution6",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail6Page.vue"
      ),
  },
  {
    path: "/solution/7",
    name: "solution7",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail7Page.vue"
      ),
  },
  {
    path: "/solution/8",
    name: "solution8",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail8Page.vue"
      ),
  },
  {
    path: "/solution/9",
    name: "solution9",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail9Page.vue"
      ),
  },
  {
    path: "/solution/10",
    name: "solution10",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Solution/Detail10Page.vue"
      ),
  },
  {
    path: "/product",
    name: "product",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Product/IndexPage.vue"),
  },
  {
    path: "/product/1",
    name: "product1",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail1Page.vue"
      ),
  },
  {
    path: "/product/2",
    name: "product2",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail2Page.vue"
      ),
  },
  {
    path: "/product/3",
    name: "product3",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail3Page.vue"
      ),
  },
  {
    path: "/product/4",
    name: "product4",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail4Page.vue"
      ),
  },
  {
    path: "/product/5",
    name: "product5",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail5Page.vue"
      ),
  },
  {
    path: "/product/6",
    name: "product6",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail6Page.vue"
      ),
  },
  {
    path: "/product/7",
    name: "product7",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail7Page.vue"
      ),
  },
  {
    path: "/product/8",
    name: "product8",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail8Page.vue"
      ),
  },
  {
    path: "/product/9",
    name: "product9",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail9Page.vue"
      ),
  },
  {
    path: "/product/10",
    name: "product10",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail10Page.vue"
      ),
  },
  {
    path: "/product/11",
    name: "product11",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail11Page.vue"
      ),
  },
  {
    path: "/product/12",
    name: "product12",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail12Page.vue"
      ),
  },
  {
    path: "/product/13",
    name: "product13",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail13Page.vue"
      ),
  },
  {
    path: "/product/14",
    name: "product14",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Product/Detail14Page.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
