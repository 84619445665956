<template>
  <div
    class="flex-row justify-between items-center relative section_2"
    :class="{ 'nav-transparent': isTransparent }"
    @mouseleave="hideDropMenu"
  >
    <!-- <div class="flex-row items-center space-x-7" @click="router.push('/')">
      <img class="image_2" :src="isTransparent ? logoWhite : logo" />
      <img class="image_3" :src="isTransparent ? logoTextWhite : logoText" />
      <div class="flex-col items-start group_2">
        <span class="text">873496</span>
        <span class="text_2">股票代码</span>
      </div>
    </div> -->
    <img
      class="image_1"
      :src="isTransparent ? logoWhite : logo"
      @click="router.push('/')"
    />
    <div class="flex-row space-x-62">
      <div
        class="flex-row items-center shrink-0 space-x-10"
        @click="router.push('/solution')"
        @mouseenter="hoverSolution"
      >
        <span class="font_1">解决方案</span>
        <img
          class="shrink-0 image_4"
          :src="isTransparent ? iconWhite : iconBlack"
        />
      </div>
      <div
        class="flex-row items-center shrink-0 space-x-10"
        @click="router.push('/product')"
        @mouseenter="hoverProduct"
      >
        <span class="font_1">产品服务</span>
        <img
          class="shrink-0 image_4"
          :src="isTransparent ? iconWhite : iconBlack"
        />
      </div>
      <span class="font_1" @click="jumpPage('/news')">新闻动态</span>
      <span class="font_1" @click="jumpPage('/investor')">投资者关系</span>
      <span class="font_1" @click="jumpPage('/propaganda')">防非宣传</span>
      <span class="font_1" @click="jumpPage('/about')">关于崤云</span>
    </div>
    <div class="nav-menu" v-show="visible">
      <div class="nav-menu-type" v-for="menu in dropMenu" :key="menu.title">
        <div class="flex-row nav-menu-title">{{ menu.title }}</div>
        <span
          @click="jumpPage(item.path)"
          v-for="item in menu.list"
          :key="item.path"
          class="nav-menu-item"
          >{{ item.name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, shallowRef, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const solution = [
  {
    title: "数字政府",
    list: [
      {
        name: "新型智慧城市",
        path: "/solution/1",
      },
      {
        name: "智慧城管",
        path: "/solution/2",
      },
      {
        name: "全域网格管理平台",
        path: "/solution/3",
      },
      {
        name: "互联网+政务服务",
        path: "/solution/4",
      },
      {
        name: "智慧不动产综合管理服务平台",
        path: "/solution/5",
      },
      {
        name: "工程建设项目审批管理系统",
        path: "/solution/6",
      },
    ],
  },
  {
    title: "数字社会",
    list: [
      {
        name: "120急救中心市县一体化急救系统",
        path: "/solution/7",
      },
      {
        name: "视联网管控平台",
        path: "/solution/8",
      },
      {
        name: "智慧消防物联网平台",
        path: "/solution/9",
      },
    ],
  },
  {
    title: "数字经济",
    list: [
      {
        name: "普惠金融服务平台",
        path: "/solution/10",
      },
    ],
  },
];

const product = [
  {
    title: "数字政府",
    list: [
      {
        name: "智慧政务协同一体化办公平台",
        path: "/product/1",
      },
      {
        name: "政务云平台",
        path: "/product/2",
      },
      {
        name: "电子政务外网",
        path: "/product/3",
      },
      {
        name: "集约化网站",
        path: "/product/4",
      },
      {
        name: "数据服务",
        path: "/product/5",
      },
      {
        name: "安全服务",
        path: "/product/14",
      },
    ],
  },
  {
    title: "数字社会",
    list: [
      {
        name: "城市服务APP",
        path: "/product/6",
      },
      {
        name: "12345政务服务热线平台",
        path: "/product/7",
      },
      {
        name: "数字化疫情防控平台",
        path: "/product/8",
      },
      {
        name: "智慧人防",
        path: "/product/9",
      },
    ],
  },
  {
    title: "数字经济",
    list: [
      {
        name: "安全生产服务平台",
        path: "/product/10",
      },
      {
        name: "双重预防平台",
        path: "/product/11",
      },
      {
        name: "基于视频流的可视化行为分析系统",
        path: "/product/12",
      },
      {
        name: "轻云视频会议系统",
        path: "/product/13",
      },
    ],
  },
];

const dropMenu = shallowRef([]);
const visible = ref(false);

const hoverSolution = () => {
  visible.value = true;
  dropMenu.value = solution;
};

const hoverProduct = () => {
  visible.value = true;
  dropMenu.value = product;
};

const hideDropMenu = () => {
  visible.value = false;
};

const jumpPage = (path) => {
  router.push(path);
  hideDropMenu();
};

const logo = require("../../assets/logo.png");
const logoWhite = require("../../assets/logo-white.png");
const iconBlack = require("../../assets/77ee024b6ac35d17addc98d05e3f863f.png");
const iconWhite = require("../../assets/0c3918e744c79582be21063a3ea808c6.png");

const isScroll = ref(false);
const isHomePath = ref(true);

const isTransparent = computed(() => !isScroll.value && isHomePath.value);

const router = useRouter();
const route = useRoute();

watch(
  () => route.path,
  (newPath) => {
    if (newPath === "/") {
      isHomePath.value = true;
    } else {
      isHomePath.value = false;
    }

    const app = document.getElementById("app");
    // behavior: "smooth"，设置滚动过程动画过渡效果为smooth
    // block: "start",定义垂直方向的对齐方式为 start ，即顶部对齐
    // inline: "start"，定义水平方向的对齐方式为 start
    app.scrollIntoView({
      block: "start",
      inline: "start",
    });
  }
);

const handleScroll = () => {
  const top = Math.floor(
    document.body.scrollTop ||
      document.documentElement.scrollTop ||
      window.pageYOffset
  );
  if (top > 50) {
    isScroll.value = true;
  } else {
    isScroll.value = false;
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped lang="scss">
.section_2 {
  padding: 0.5208vw 18.75vw 0.5208vw 18.9583vw;
  background-color: #ffffff;
  width: 100vw;
  position: fixed;
  z-index: 999;
  .image_1 {
    width: 11.4583vw;
    height: 3.125vw;
    cursor: pointer;
  }
  .space-x-7 {
    cursor: pointer;
    & > *:not(:first-child) {
      margin-left: 7px;
    }
    .image_2 {
      width: 2.7083vw;
      height: 1.7708vw;
    }
    .image_3 {
      width: 4.1146vw;
      height: 1.4583vw;
    }
    .group_2 {
      width: 3.5417vw;
      padding-left: 10px;
      border-left: solid 0.05208vw #041332;
      .text {
        color: #041332;
        font-size: 0.7292vw;
        font-family: "OPPOSans";
        font-weight: 500;
        line-height: 0.9375vw;
      }
      .text_2 {
        color: #041332;
        font-size: 0.625vw;
        font-family: "Source Han Sans CN";
        font-weight: 200;
        line-height: 0.625vw;
        letter-spacing: 0.1042vw;
        white-space: nowrap;
      }
    }
  }
  .space-x-62 {
    & > *:not(:first-child) {
      margin-left: 3.2292vw;
    }
    .space-x-10 {
      & > *:not(:first-child) {
        margin-left: 0.5208vw;
      }
      .image_4 {
        width: 0.7292vw;
        height: 0.4167vw;
      }
    }
    .font_1 {
      font-size: 0.8333vw;
      font-family: "Source Han Sans CN";
      line-height: 0.8333vw;
      color: #041332;
      cursor: pointer;
    }
  }
}

.nav-transparent {
  background: transparent;

  .space-x-62 {
    .font_1 {
      color: white;
    }
  }

  .space-x-7 {
    .group_2 {
      border-left: solid 0.05208vw white;
      .text,
      .text_2 {
        color: white;
      }
    }
  }
}

.nav-menu {
  display: flex;
  position: absolute;
  top: 4.1667vw;
  left: 41.6667vw;
  padding: 1.0417vw 1.0417vw 1.3021vw 1.0417vw;
  background-color: #fafbfe;
  box-shadow: 0 0 1.5625vw 0 rgb(0 45 134 / 10%);

  .nav-menu-type {
    width: 10.4167vw;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-left: 1.0417vw;
    }

    .nav-menu-title {
      height: 2.34375vw;
      font-size: 0.8333vw;
      color: #3272f1;
      border-bottom: solid 1px #e6e6e6;
      margin-bottom: 0.2604vw;
    }

    .nav-menu-item {
      width: 10.4167vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 48px;
      height: 2.5vw;
      font-size: 0.729267vw;
      color: #666666;
      cursor: pointer;

      &:hover {
        color: #3272f1;
      }
    }
  }
}
</style>
